//import Vue from 'vue'
//import VueRouter from 'vue-router'




import { createWebHistory, createRouter } from "vue-router";




  
  var routes = [


   

    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
      },

     
     

      
     
      



    
  ]









const router = new createRouter({
//const router = new VueRouter({
  history: createWebHistory(),
  props: true,
  routes
})

/*
router.beforeEach((to, from, next) => {
  document.title = document.location.href.split('/')[document.location.href.split('/').length - 1] + " Word Search"
  next();
});
*/

export default router
