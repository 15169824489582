<template>
  <metainfo>
    <template v-slot:description="{ content }">{{ content  }}</template>
  </metainfo>
  <div class='' id="mainContainer">

    
    
    <router-view/>
    
  </div>
</template>




<style>

  /*

  @font-face {
    font-display: block;  
    font-family: "Manrope";
    src: local("Manrope-Regular"),
    url(./assets/fonts/Manrope-Regular.ttf) format("truetype");
    
  }

  */

</style>
